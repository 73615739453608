<app-navbar-style-one></app-navbar-style-one>

<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2>Home Broadband</h2>
            <ul>
                <li><a href="index.html">Home</a></li>
                <li>Home Broadband</li>
            </ul>
        </div>
    </div>
</div>

<section class="services-details-area ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-8 col-md-12">
                <div class="services-details">
                    <img src="assets/img/services-details1.jpg" alt="image">

                    <div class="services-details-desc">
                        <h3>Whenever it comes to financial matters, customer Engagement matters more than more.</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.</p>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo.</p>
                        <h3>Research about project</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.</p>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices.</p>
                        <div class="services-details-faq">
                            <ul class="accordion">
                                <li class="accordion-item">
                                    <a class="accordion-title active" href="javascript:void(0)">Branding is simply a more efficient way? <i class="fas fa-plus"></i></a>
                                    <p class="accordion-content show">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. incididunt ut labore et dolore magna aliqua.</p>
                                </li>
                                <li class="accordion-item">
                                    <a class="accordion-title" href="javascript:void(0)">
                                        Why we are the best in this fields? <i class="fas fa-plus"></i></a>
                                    <p class="accordion-content">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. incididunt ut labore et dolore magna aliqua.</p>
                                </li>
                                <li class="accordion-item">
                                    <a class="accordion-title" href="javascript:void(0)">It’s better to be first in the mind than in the market? <i class="fas fa-plus"></i></a>
                                    <p class="accordion-content">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. incididunt ut labore et dolore magna aliqua.</p>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-12">
                <div class="services-sidebar">
                    <div class="services-list">
                        <ul>
                            <li><a routerLink="/single-services" class="active">Home Broadband <i class="flaticon-arrow-pointing-to-right"></i></a></li>
                            <li><a routerLink="/single-services">Home WIFI <i class="flaticon-arrow-pointing-to-right"></i></a></li>
                            <li><a routerLink="/single-services">Satellite TV <i class="flaticon-arrow-pointing-to-right"></i></a></li>
                            <li><a routerLink="/single-services">Nexa TV Box <i class="flaticon-arrow-pointing-to-right"></i></a></li>
                            <li><a routerLink="/single-services">Mobile Connection <i class="flaticon-arrow-pointing-to-right"></i></a></li>
                            <li><a routerLink="/single-services">Home Security <i class="flaticon-arrow-pointing-to-right"></i></a></li>
                        </ul>
                    </div>

                    <div class="services-download-list">
                        <ul>
                            <li><a routerLink="/single-services"><i class="flaticon-pdf"></i> Download Pdf File</a></li>
                            <li><a routerLink="/single-services"><i class="flaticon-txt"></i> Download Txt File</a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<app-footer-style-one></app-footer-style-one>