import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeOneComponent } from './components/pages/home-one/home-one.component';
import { HomeTwoComponent } from './components/pages/home-two/home-two.component';
import { PreloaderComponent } from './components/common/preloader/preloader.component';
import { NavbarStyleOneComponent } from './components/common/navbar-style-one/navbar-style-one.component';
import { NavbarStyleTwoComponent } from './components/common/navbar-style-two/navbar-style-two.component';
import { FooterStyleOneComponent } from './components/common/footer-style-one/footer-style-one.component';
import { FooterStyleTwoComponent } from './components/common/footer-style-two/footer-style-two.component';
import { OfferComponent } from './components/common/offer/offer.component';
import { AboutUsOneComponent } from './components/pages/about-us-one/about-us-one.component';
import { AboutUsTwoComponent } from './components/pages/about-us-two/about-us-two.component';
import { TeamComponent } from './components/pages/team/team.component';
import { PricingComponent } from './components/pages/pricing/pricing.component';
import { ErrorComponent } from './components/pages/error/error.component';
import { FaqComponent } from './components/pages/faq/faq.component';
import { ServicesStyleOneComponent } from './components/pages/services-style-one/services-style-one.component';
import { ServicesStyleTwoComponent } from './components/pages/services-style-two/services-style-two.component';
import { ServicesDetailsComponent } from './components/pages/services-details/services-details.component';
import { ProductsComponent } from './components/pages/products/products.component';
import { ProductsDetailsComponent } from './components/pages/products-details/products-details.component';
import { CartComponent } from './components/pages/cart/cart.component';
import { CheckoutComponent } from './components/pages/checkout/checkout.component';
import { BlogGridComponent } from './components/pages/blog-grid/blog-grid.component';
import { BlogRightSidebarComponent } from './components/pages/blog-right-sidebar/blog-right-sidebar.component';
import { BlogDetailsComponent } from './components/pages/blog-details/blog-details.component';
import { ContactComponent } from './components/pages/contact/contact.component';
import { ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { TermsAndConditionsComponent } from './components/pages/terms-and-conditions/terms-and-conditions';
import { PrivacyPolicyComponent } from './components/pages/privacy-policy/privacy-policy';
import { CacellationComponent } from './components/pages/cacellation/cacellation';
import { ShippingAndDeliveryPolicyComponent } from './components/pages/shipping-and-delivery-policy/shipping-and-delivery-policy';

@NgModule({
  declarations: [
    AppComponent,
    HomeOneComponent,
    HomeTwoComponent,
    PreloaderComponent,
    NavbarStyleOneComponent,
    NavbarStyleTwoComponent,
    FooterStyleOneComponent,
    FooterStyleTwoComponent,
    OfferComponent,
    AboutUsOneComponent,
    AboutUsTwoComponent,
    TeamComponent,
    PricingComponent,
    ErrorComponent,
    FaqComponent,
    ServicesStyleOneComponent,
    ServicesStyleTwoComponent,
    ServicesDetailsComponent,
    ProductsComponent,
    ProductsDetailsComponent,
    CartComponent,
    CheckoutComponent,
    BlogGridComponent,
    BlogRightSidebarComponent,
    BlogDetailsComponent,
    ContactComponent,
    TermsAndConditionsComponent,
    PrivacyPolicyComponent,
    CacellationComponent,
    ShippingAndDeliveryPolicyComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ReactiveFormsModule,
    HttpClientModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
