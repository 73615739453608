<footer class="footer-area">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-4 col-sm-4">
                <div class="single-footer-widget">
                    <div class="logo">
                        <a routerLink="/"><img src="assets/img/logo.png" alt="image"></a>
                        <p>Airnet360 strives to provide the best out of all with fiber optics that make data travels quickly, resulting in greater uploading capacity so there's more bandwidth available for everyone in your house at the same time. This is where we make a difference in the crowd.</p>
                    </div>
                </div>
            </div>

           

            <div class="col-lg-4 col-md-4 col-sm-4">
                <div class="single-footer-widget">
                    <h3>Terms and policies</h3>
                    <div class="widget-contact-info">
                       <ul class="ps-0">
                        <li class="mb-1"><a routerLink="/terms-and-conditions" class="text-white">Terms and Conditions</a></li>
                        <li class="mb-1"><a routerLink="/privacy-policy" class="text-white">Privacy Policy</a></li>
                        <li class="mb-1"><a routerLink="/shipping-and-delivery-policy" class="text-white">Shipping and Delivery Policy</a></li>
                        <li><a routerLink="/cancellation-and-refund-policy" class="text-white">Cancellation and Refund Policy</a></li>
                       </ul>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-4 col-sm-4">
                <div class="single-footer-widget">
                    <h3>Get In Touch</h3>

                    <div class="widget-contact-info">
                        <p>
                            <span class="d-lg-flex"><a href="tel:07314894499">0731-4894499, </a><a href="tel:07314894488" class="ms-lg-2">0731-4894488 </a></span>
                            <span>(Customer service and support)</span>
                            Office No. 404, 1 New Palasia Silver Arched Indore Madhya Pradesh 452001
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="copyright-area">
        <div class="container">
            <p>© 2013-22 Syntego Technologies (India) Private Limited </p>
        </div>
    </div>
</footer>

<div class="go-top"><i class="fas fa-arrow-up"></i></div>