import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ContactService {
  constructor(private http: HttpClient) { }
  postContact(body: any): Observable<any> {
    return this.http
      .post<any>(`https://airnet360.com/backend/sendmail.php`,body);
  }
}