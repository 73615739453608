<app-navbar-style-two></app-navbar-style-two>

<div class="page-title-area page-title-style-two">
    <div class="container">
        <div class="page-title-content">
            <h2>Services</h2>
            <ul>
                <li><a href="index.html">Home</a></li>
                <li>Services</li>
            </ul>
        </div>
    </div>
</div>

<section class="services-area ptb-100">
    <div class="container">
        <div class="section-title">
            <span>
                <span class="icon">
                    <i class="flaticon-technical-support"></i>
                </span>
                <span>Vimi Services</span>
            </span>
            <h2>Explore the next Isp unlimited possibilities</h2>
        </div>
        <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="services-box">
                    <div class="icon">
                        <i class="flaticon-router"></i>
                    </div>
                    <h3>Home Broadband</h3>
                    <p>Lorem ipsu dolors itamet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.</p>
                    <a routerLink="/single-services" class="details-btn"><i class="flaticon-arrow-pointing-to-right"></i></a>
                    <div class="image-box">
                        <img src="assets/img/shape-image/1.png" alt="image">
                        <img src="assets/img/shape-image/1.png" alt="image">
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="services-box">
                    <div class="icon">
                        <i class="flaticon-wifi-1"></i>
                    </div>
                    <h3>Home WIFI</h3>
                    <p>Lorem ipsu dolors itamet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.</p>
                    <a routerLink="/single-services" class="details-btn"><i class="flaticon-arrow-pointing-to-right"></i></a>
                    <div class="image-box">
                        <img src="assets/img/shape-image/1.png" alt="image">
                        <img src="assets/img/shape-image/1.png" alt="image">
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="services-box">
                    <div class="icon">
                        <i class="flaticon-wifi-signal-tower"></i>
                    </div>
                    <h3>Satellite TV</h3>
                    <p>Lorem ipsu dolors itamet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.</p>
                    <a routerLink="/single-services" class="details-btn"><i class="flaticon-arrow-pointing-to-right"></i></a>
                    <div class="image-box">
                        <img src="assets/img/shape-image/1.png" alt="image">
                        <img src="assets/img/shape-image/1.png" alt="image">
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="services-box">
                    <div class="icon">
                        <i class="flaticon-online-shop"></i>
                    </div>
                    <h3>Nexa TV Box</h3>
                    <p>Lorem ipsu dolors itamet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.</p>
                    <a routerLink="/single-services" class="details-btn"><i class="flaticon-arrow-pointing-to-right"></i></a>
                    <div class="image-box">
                        <img src="assets/img/shape-image/1.png" alt="image">
                        <img src="assets/img/shape-image/1.png" alt="image">
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="services-box">
                    <div class="icon">
                        <i class="flaticon-key"></i>
                    </div>
                    <h3>Mobile Connection</h3>
                    <p>Lorem ipsu dolors itamet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.</p>
                    <a routerLink="/single-services" class="details-btn"><i class="flaticon-arrow-pointing-to-right"></i></a>
                    <div class="image-box">
                        <img src="assets/img/shape-image/1.png" alt="image">
                        <img src="assets/img/shape-image/1.png" alt="image">
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="services-box">
                    <div class="icon">
                        <i class="flaticon-shield"></i>
                    </div>
                    <h3>Home Security</h3>
                    <p>Lorem ipsu dolors itamet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.</p>
                    <a routerLink="/single-services" class="details-btn"><i class="flaticon-arrow-pointing-to-right"></i></a>
                    <div class="image-box">
                        <img src="assets/img/shape-image/1.png" alt="image">
                        <img src="assets/img/shape-image/1.png" alt="image">
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="why-choose-us-area ptb-100 bg-fcfcfc">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-5 col-md-12">
                <div class="why-choose-us-content">
                    <span>Vimi Offer</span>
                    <h2>More Great Reasons to Choose Vimi</h2>
                    <p>The speed of data, as it travels from the Internet to your computer, is measured in megabits per second (Mbps). Different activities require different speeds.</p>
                    <ul>
                        <li>50% off on professional installation</li>
                        <li>10 hours of cloud DVR service</li>
                        <li>HD included</li>
                        <li>Speed good for 6-8 devices at the same time</li>
                    </ul>
                    <a routerLink="/contact" class="btn btn-primary">Request A Quote</a>
                </div>
            </div>

            <div class="col-lg-7 col-md-12">
                <div class="why-choose-us-image">
                    <img src="assets/img/offer-img1.png" alt="image">
                    <div class="text">
                        <div class="inner-text">
                            <span>Spend</span>
                            <h3>$12.00</h3>
                            <span>Up to</span>
                            <h3>$56.00</h3>
                            <span>Per Second</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<app-offer></app-offer>

<app-footer-style-one></app-footer-style-one>