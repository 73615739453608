import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ContactService } from 'src/app/services/contact.service';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {
  contactForm : FormGroup;
  submitted   : boolean = false;
  isLoading   : boolean = false;
  showMessage : boolean = false;
  constructor(
    private fb: FormBuilder,
    private contactService:ContactService
  ) { }

  ngOnInit(): void {
    this.validation()
  }
  validation() {
    this.contactForm = this.fb.group({
      name : ['',Validators.required],
      email: ['', Validators.compose([Validators.required, Validators.pattern(/^(\d{10}|\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{1,9}))$/)])], 
      msg_subject : ['',Validators.required],
      phone_number : ['',Validators.required],
      message : ['',Validators.required],
          
    });
  }
  //end
    // Validators message
    get f(): { [key: string]: AbstractControl } {
      return this.contactForm.controls;
    }
    //end
    //form submit
  onSubmit() {
    this.submitted = true;
    if (!this.contactForm.valid) {
      return;
    } else {
      this.isLoading = true;
      var body = new FormData( );
      body.append("name", this.contactForm.get('name').value);
      body.append("email", this.contactForm.get('email').value);
      body.append("msg_subject", this.contactForm.get('msg_subject').value);
      body.append("phone_number", this.contactForm.get('phone_number').value);
      body.append("message", this.contactForm.get('message').value);
      this.contactService
        .postContact(body)
        .subscribe((res:any) => {
         if (res == true) {
          this.contactForm.reset()
          this.submitted = false
          this.isLoading = false
          this.showMessage    = true
    
         } 
        })
    }
  }
}
