<app-navbar-style-one></app-navbar-style-one>

<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2>Shipping & Delivery Policy</h2>
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li>Shipping & Delivery Policy</li>
            </ul>
        </div>
    </div>
</div>


<section class="ptb-40 ">
    <div class="container">
        <div class="row">
            <div class="col-lg-12 col-md-12 col-sm-12">
              <p>Shipping is not applicable for business.</p>
            </div>

        </div>
    </div>
</section>



<app-footer-style-one></app-footer-style-one>