<header class="header-area">
    <div class="top-header">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-5 col-md-5">
                    <div class="top-header-left">
                        <p><span>Call Now At: </span> <a href="tel:07314894499">0731-4894499</a>, <a href="tel:07314894488">0731-4894488</a></p>
                    </div>
                </div>

            </div>
        </div>
    </div>
    
    <div class="navbar-area">
        <div class="vimi-mobile-nav">
            <div class="logo">
                <a routerLink="/"><img src="assets/img/logo.png" alt="logo"></a>
            </div>
        </div>

        <div class="vimi-nav">
            <div class="container">
                <nav class="navbar navbar-expand-md navbar-light">
                    <a class="navbar-brand" routerLink="/"><img src="assets/img/logo.png" alt="logo"></a>

                    <div class="collapse navbar-collapse mean-menu" id="navbarSupportedContent">
                        <ul class="navbar-nav">

                            <li class="nav-item">
                                <a routerLink="/about-us-1" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">About Us</a>
                            </li>


                            <li class="nav-item"><a routerLink="/contact" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Contact</a></li>
                        </ul>

                        <div class="others-options">
                            <a routerLink="/contact" class="btn btn-primary">Get Started</a>
                        </div>
                    </div>
                </nav>
            </div>
        </div>
    </div>
</header>