<app-navbar-style-one></app-navbar-style-one>

<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2>Team</h2>
            <ul>
                <li><a href="index.html">Home</a></li>
                <li>Team</li>
            </ul>
        </div>
    </div>
</div>

<section class="team-area ptb-100">
    <div class="container">
        <div class="single-team-member">
            <div class="member-image">
                <img src="assets/img/team-img1.jpg" alt="image">
            </div>
            <div class="member-content">
                <h3>Agaton Ronald</h3>
                <span>CEO & Founder</span>
                <ul class="info">
                    <li><span>Experience:</span> 7 Years</li>
                    <li><span>Phone:</span> <a href="tel:+2976432998">+2(976) 432-998</a></li>
                    <li><span>E-mail:</span> <a href="mailto:info@yoursitename.com">info@yoursitename.com</a></li>
                    <li><span>Location:</span> 176, Street Name, New York, NY 10014176, USA</li>
                </ul>
                <ul class="social">
                    <li><a href="#" target="_blank"><i class="fab fa-twitter"></i></a></li>
                    <li><a href="#" target="_blank"><i class="fab fa-youtube"></i></a></li>
                    <li><a href="#" target="_blank"><i class="fab fa-facebook-f"></i></a></li>
                    <li><a href="#" target="_blank"><i class="fab fa-linkedin-in"></i></a></li>
                    <li><a href="#" target="_blank"><i class="fab fa-instagram"></i></a></li>
                </ul>
            </div>
        </div>

        <div class="single-team-member">
            <div class="member-image">
                <img src="assets/img/team-img2.jpg" alt="image">
            </div>
            <div class="member-content">
                <h3>Sarah Taylor</h3>
                <span>Worker</span>
                <ul class="info">
                    <li><span>Experience:</span> 7 Years</li>
                    <li><span>Phone:</span> <a href="tel:+2976432998">+2(976) 432-998</a></li>
                    <li><span>E-mail:</span> <a href="mailto:info@yoursitename.com">info@yoursitename.com</a></li>
                    <li><span>Location:</span> 176, Street Name, New York, NY 10014176, USA</li>
                </ul>
                <ul class="social">
                    <li><a href="#" target="_blank"><i class="fab fa-twitter"></i></a></li>
                    <li><a href="#" target="_blank"><i class="fab fa-youtube"></i></a></li>
                    <li><a href="#" target="_blank"><i class="fab fa-facebook-f"></i></a></li>
                    <li><a href="#" target="_blank"><i class="fab fa-linkedin-in"></i></a></li>
                    <li><a href="#" target="_blank"><i class="fab fa-instagram"></i></a></li>
                </ul>
            </div>
        </div>

        <div class="single-team-member">
            <div class="member-image">
                <img src="assets/img/team-img3.jpg" alt="image">
            </div>
            <div class="member-content">
                <h3>Robert Reed</h3>
                <span>Worker</span>
                <ul class="info">
                    <li><span>Experience:</span> 7 Years</li>
                    <li><span>Phone:</span> <a href="tel:+2976432998">+2(976) 432-998</a></li>
                    <li><span>E-mail:</span> <a href="mailto:info@yoursitename.com">info@yoursitename.com</a></li>
                    <li><span>Location:</span> 176, Street Name, New York, NY 10014176, USA</li>
                </ul>
                <ul class="social">
                    <li><a href="#" target="_blank"><i class="fab fa-twitter"></i></a></li>
                    <li><a href="#" target="_blank"><i class="fab fa-youtube"></i></a></li>
                    <li><a href="#" target="_blank"><i class="fab fa-facebook-f"></i></a></li>
                    <li><a href="#" target="_blank"><i class="fab fa-linkedin-in"></i></a></li>
                    <li><a href="#" target="_blank"><i class="fab fa-instagram"></i></a></li>
                </ul>
            </div>
        </div>

        <div class="single-team-member">
            <div class="member-image">
                <img src="assets/img/team-img4.jpg" alt="image">
            </div>
            <div class="member-content">
                <h3>Joe Root</h3>
                <span>Support</span>
                <ul class="info">
                    <li><span>Experience:</span> 7 Years</li>
                    <li><span>Phone:</span> <a href="tel:+2976432998">+2(976) 432-998</a></li>
                    <li><span>E-mail:</span> <a href="mailto:info@yoursitename.com">info@yoursitename.com</a></li>
                    <li><span>Location:</span> 176, Street Name, New York, NY 10014176, USA</li>
                </ul>
                <ul class="social">
                    <li><a href="#" target="_blank"><i class="fab fa-twitter"></i></a></li>
                    <li><a href="#" target="_blank"><i class="fab fa-youtube"></i></a></li>
                    <li><a href="#" target="_blank"><i class="fab fa-facebook-f"></i></a></li>
                    <li><a href="#" target="_blank"><i class="fab fa-linkedin-in"></i></a></li>
                    <li><a href="#" target="_blank"><i class="fab fa-instagram"></i></a></li>
                </ul>
            </div>
        </div>

        <div class="single-team-member">
            <div class="member-image">
                <img src="assets/img/team-img5.jpg" alt="image">
            </div>
            <div class="member-content">
                <h3>Heather Knight</h3>
                <span>Support</span>
                <ul class="info">
                    <li><span>Experience:</span> 7 Years</li>
                    <li><span>Phone:</span> <a href="tel:+2976432998">+2(976) 432-998</a></li>
                    <li><span>E-mail:</span> <a href="mailto:info@yoursitename.com">info@yoursitename.com</a></li>
                    <li><span>Location:</span> 176, Street Name, New York, NY 10014176, USA</li>
                </ul>
                <ul class="social">
                    <li><a href="#" target="_blank"><i class="fab fa-twitter"></i></a></li>
                    <li><a href="#" target="_blank"><i class="fab fa-youtube"></i></a></li>
                    <li><a href="#" target="_blank"><i class="fab fa-facebook-f"></i></a></li>
                    <li><a href="#" target="_blank"><i class="fab fa-linkedin-in"></i></a></li>
                    <li><a href="#" target="_blank"><i class="fab fa-instagram"></i></a></li>
                </ul>
            </div>
        </div>
    </div>
</section>

<app-footer-style-one></app-footer-style-one>