<footer class="footer-style-two">
    <div class="container">
        <div class="row">
            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="single-footer-widget">
                    <h3>Customer Services</h3>

                    <ul class="services-widget-list">
                        <li><a routerLink="/">Parental Controls</a></li>
                        <li><a routerLink="/">Check Email</a></li>
                        <li><a routerLink="/">Check Voicemail</a></li>
                        <li><a routerLink="/">Manage Your Plan</a></li>
                        <li><a routerLink="/">Group Counseling</a></li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="single-footer-widget">
                    <h3>My Account</h3>

                    <ul class="account-widget-list">
                        <li><a routerLink="/">Pay Bill</a></li>
                        <li><a routerLink="/">Manage My Account</a></li>
                        <li><a routerLink="/">Manage Users & Alerts</a></li>
                        <li><a routerLink="/">Move Services</a></li>
                        <li><a routerLink="/">Cable Customer Agreement</a></li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="single-footer-widget">
                    <h3>Support</h3>

                    <ul class="links-widget-list">
                        <li><a routerLink="/">Comcast Customer Service</a></li>
                        <li><a routerLink="/">Bill & Payments</a></li>
                        <li><a routerLink="/">Contact Us</a></li>
                        <li><a routerLink="/">Support Forums</a></li>
                        <li><a routerLink="/">Store Locator</a></li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="single-footer-widget">
                    <h3>Call now for Services!</h3>

                    <ul class="footer-contact-info">
                        <li><a href="tel:+1234567890">+123-456-7890</a></li>
                        <li><a href="mailto:hello@vimi.com"><i class="fas fa-envelope"></i> Email Us</a></li>
                        <li>123, New Lenox Chicago, IL, 60606</li>
                        <li><a href="#" target="_blank"><i class="fas fa-map-marker-alt"></i> Get Direction</a></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>

    <div class="copyright-area">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-6 col-sm-6">
                    <ul class="social">
                        <li><a href="#" target="_blank"><i class="fab fa-facebook-f"></i></a></li>
                        <li><a href="#" target="_blank"><i class="fab fa-twitter"></i></a></li>
                        <li><a href="#" target="_blank"><i class="fab fa-linkedin-in"></i></a></li>
                        <li><a href="#" target="_blank"><i class="fab fa-instagram"></i></a></li>
                    </ul>
                </div>

                <div class="col-lg-6 col-md-6 col-sm-6">
                    <p>© Vimi is Proudly Owned by <a href="https://envytheme.com/" target="_blank">EnvyTheme</a></p>
                </div>
            </div>
        </div>
    </div>
</footer>

<div class="go-top"><i class="fas fa-arrow-up"></i></div>