<app-navbar-style-two></app-navbar-style-two>

<div class="page-title-area page-title-style-two">
    <div class="container">
        <div class="page-title-content">
            <h2>Blog</h2>
            <ul>
                <li><a href="index.html">Home</a></li>
                <li>Blog</li>
            </ul>
        </div>
    </div>
</div>

<section class="blog-area ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-8 col-md-12">
                <div class="row">
                    <div class="col-lg-6 col-md-6">
                        <div class="single-blog-post">
                            <a routerLink="/single-blog" class="post-image"><img src="assets/img/blog-img1.jpg" alt="blog-image"></a>
                            <div class="blog-post-content">
                                <ul>
                                    <li><a routerLink="/blog-grid">Admin</a></li>
                                    <li><i class="far fa-clock"></i> January 23, 2020</li>
                                </ul>
                                <h3><a routerLink="/single-blog">I Used The Web For A Day On A 50 MB Budget</a></h3>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi turpis massa, dapibus nec libero vitae.</p>
                                <a routerLink="/single-blog" class="read-more-btn">Read More <i class="fas fa-angle-double-right"></i></a>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-6">
                        <div class="single-blog-post">
                            <a routerLink="/single-blog" class="post-image"><img src="assets/img/blog-img2.jpg" alt="blog-image"></a>
                            <div class="blog-post-content">
                                <ul>
                                    <li><a routerLink="/blog-grid">Admin</a></li>
                                    <li><i class="far fa-clock"></i> January 25, 2020</li>
                                </ul>
                                <h3><a routerLink="/single-blog">The hardest leadership advice to follow</a></h3>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi turpis massa, dapibus nec libero vitae.</p>
                                <a routerLink="/single-blog" class="read-more-btn">Read More <i class="fas fa-angle-double-right"></i></a>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-6">
                        <div class="single-blog-post">
                            <a routerLink="/single-blog" class="post-image"><img src="assets/img/blog-img3.jpg" alt="blog-image"></a>
                            <div class="blog-post-content">
                                <ul>
                                    <li><a routerLink="/blog-grid">Admin</a></li>
                                    <li><i class="far fa-clock"></i> January 27, 2020</li>
                                </ul>
                                <h3><a routerLink="/single-blog">The Most Popular New Top Business Apps</a></h3>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi turpis massa, dapibus nec libero vitae.</p>
                                <a routerLink="/single-blog" class="read-more-btn">Read More <i class="fas fa-angle-double-right"></i></a>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-6">
                        <div class="single-blog-post">
                            <a routerLink="/single-blog" class="post-image"><img src="assets/img/blog-img4.jpg" alt="blog-image"></a>
                            <div class="blog-post-content">
                                <ul>
                                    <li><a routerLink="/blog-grid">Admin</a></li>
                                    <li><i class="far fa-clock"></i> January 27, 2020</li>
                                </ul>
                                <h3><a routerLink="/single-blog">How to change yourself for the better</a></h3>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi turpis massa, dapibus nec libero vitae.</p>
                                <a routerLink="/single-blog" class="read-more-btn">Read More <i class="fas fa-angle-double-right"></i></a>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-6">
                        <div class="single-blog-post">
                            <a routerLink="/single-blog" class="post-image"><img src="assets/img/blog-img5.jpg" alt="blog-image"></a>
                            <div class="blog-post-content">
                                <ul>
                                    <li><a routerLink="/blog-grid">Admin</a></li>
                                    <li><i class="far fa-clock"></i> January 27, 2020</li>
                                </ul>
                                <h3><a routerLink="/single-blog">10 Tactics for marketing your company</a></h3>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi turpis massa, dapibus nec libero vitae.</p>
                                <a routerLink="/single-blog" class="read-more-btn">Read More <i class="fas fa-angle-double-right"></i></a>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-6">
                        <div class="single-blog-post">
                            <a routerLink="/single-blog" class="post-image"><img src="assets/img/blog-img6.jpg" alt="blog-image"></a>
                            <div class="blog-post-content">
                                <ul>
                                    <li><a routerLink="/blog-grid">Admin</a></li>
                                    <li><i class="far fa-clock"></i> January 04, 2020</li>
                                </ul>
                                <h3><a routerLink="/single-blog">Top 10 Google web ranking changing much</a></h3>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi turpis massa, dapibus nec libero vitae.</p>
                                <a routerLink="/single-blog" class="read-more-btn">Read More <i class="fas fa-angle-double-right"></i></a>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-6">
                        <div class="single-blog-post">
                            <a routerLink="/single-blog" class="post-image"><img src="assets/img/blog-img7.jpg" alt="blog-image"></a>
                            <div class="blog-post-content">
                                <ul>
                                    <li><a routerLink="/blog-grid">Admin</a></li>
                                    <li><i class="far fa-clock"></i> January 21, 2020</li>
                                </ul>
                                <h3><a routerLink="/single-blog">How to be a successful entrepreneur</a></h3>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi turpis massa, dapibus nec libero vitae.</p>
                                <a routerLink="/single-blog" class="read-more-btn">Read More <i class="fas fa-angle-double-right"></i></a>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-6">
                        <div class="single-blog-post">
                            <a routerLink="/single-blog" class="post-image"><img src="assets/img/blog-img8.jpg" alt="blog-image"></a>
                            <div class="blog-post-content">
                                <ul>
                                    <li><a routerLink="/blog-grid">Admin</a></li>
                                    <li><i class="far fa-clock"></i> January 15, 2020</li>
                                </ul>
                                <h3><a routerLink="/single-blog">Protect your workplace from cyber attacks</a></h3>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi turpis massa, dapibus nec libero vitae.</p>
                                <a routerLink="/single-blog" class="read-more-btn">Read More <i class="fas fa-angle-double-right"></i></a>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-12 col-md-12">
                        <div class="pagination-area">
                            <a routerLink="/blog-right-sidebar" class="prev page-numbers"><i class="fas fa-angle-double-left"></i></a>
                            <a routerLink="/blog-right-sidebar" class="page-numbers">1</a>
                            <span class="page-numbers current" aria-current="page">2</span>
                            <a routerLink="/blog-right-sidebar" class="page-numbers">3</a>
                            <a routerLink="/blog-right-sidebar" class="page-numbers">4</a>
                            <a routerLink="/blog-right-sidebar" class="next page-numbers"><i class="fas fa-angle-double-right"></i></a>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-12">
                <aside class="widget-area" id="secondary">
                    <section class="widget widget_search">
                        <form class="search-form">
                            <label>
                                <span class="screen-reader-text">Search for:</span>
                                <input type="search" class="search-field" placeholder="Search...">
                            </label>
                            <button type="submit"><i class="fas fa-search"></i></button>
                        </form>
                    </section>

                    <section class="widget widget_vimi_posts_thumb">
                        <h3 class="widget-title">Popular Posts</h3>

                        <article class="item">
                            <a routerLink="/single-blog" class="thumb">
                                <span class="fullimage cover bg1" role="img"></span>
                            </a>
                            <div class="info">
                                <time datetime="2019-06-30">September 30, 2019</time>
                                <h4 class="title usmall"><a routerLink="/single-blog">How to change yourself for the better</a></h4>
                            </div>
                            <div class="clear"></div>
                        </article>
                        <article class="item">
                            <a routerLink="/single-blog" class="thumb">
                                <span class="fullimage cover bg2" role="img"></span>
                            </a>
                            <div class="info">
                                <time datetime="2019-06-30">September 14, 2019</time>
                                <h4 class="title usmall"><a routerLink="/single-blog">10 Tactics for marketing your company</a></h4>
                            </div>
                            <div class="clear"></div>
                        </article>
                        <article class="item">
                            <a routerLink="/single-blog" class="thumb">
                                <span class="fullimage cover bg3" role="img"></span>
                            </a>
                            <div class="info">
                                <time datetime="2019-06-30">September 13, 2019</time>
                                <h4 class="title usmall"><a routerLink="/single-blog">Google web ranking changing much</a></h4>
                            </div>
                            <div class="clear"></div>
                        </article>
                    </section>

                    <section class="widget widget_recent_comments">
                        <h3 class="widget-title">Recent Comments</h3>

                        <ul>
                            <li>
                                <span class="comment-author-link">
                                    <a routerLink="/blog-grid">A WordPress Commenter</a>
                                </span>
                                on
                                <a routerLink="/blog-grid">Hello world!</a>
                            </li>
                            <li>
                                <span class="comment-author-link">
                                    <a routerLink="/blog-grid">Semental</a>
                                </span>
                                on
                                <a routerLink="/blog-grid">Hello world!</a>
                            </li>
                            <li>
                                <span class="comment-author-link">
                                    <a routerLink="/blog-grid">Wordpress</a>
                                </span>
                                on
                                <a routerLink="/blog-grid">Hello world!</a>
                            </li>
                            <li>
                                <span class="comment-author-link">
                                    <a routerLink="/blog-grid">A WordPress Commenter</a>
                                </span>
                                on
                                <a routerLink="/blog-grid">Hello world!</a>
                            </li>
                            <li>
                                <span class="comment-author-link">
                                    <a routerLink="/blog-grid">Semental</a>
                                </span>
                                on
                                <a routerLink="/blog-grid">Hello world!</a>
                            </li>
                        </ul>
                    </section>

                    <section class="widget widget_recent_entries">
                        <h3 class="widget-title">Recent Posts</h3>

                        <ul>
                            <li><a routerLink="/single-blog">How to Become a Successful Entry Level UX Designer</a></li>
                            <li><a routerLink="/single-blog">How to start your business as an entrepreneur</a></li>
                            <li><a routerLink="/single-blog">How to be a successful entrepreneur</a></li>
                            <li><a routerLink="/single-blog">How to Become a Successful Entry Level UX Designer</a></li>
                            <li><a routerLink="/single-blog">Protect your workplace from cyber attacks</a></li>
                        </ul>
                    </section>

                    <section class="widget widget_archive">
                        <h3 class="widget-title">Archives</h3>

                        <ul>
                            <li><a routerLink="/blog-grid">May 2019</a></li>
                            <li><a routerLink="/blog-grid">April 2019</a></li>
                            <li><a routerLink="/blog-grid">June 2019</a></li>
                        </ul>
                    </section>

                    <section class="widget widget_categories">
                        <h3 class="widget-title">Categories</h3>

                        <ul>
                            <li><a routerLink="/blog-grid">Business</a></li>
                            <li><a routerLink="/blog-grid">Privacy</a></li>
                            <li><a routerLink="/blog-grid">Technology</a></li>
                            <li><a routerLink="/blog-grid">Tips</a></li>
                            <li><a routerLink="/blog-grid">Uncategorized</a></li>
                        </ul>
                    </section>

                    <section class="widget widget_meta">
                        <h3 class="widget-title">Meta</h3>

                        <ul>
                            <li><a routerLink="/blog-grid">Log in</a></li>
                            <li><a routerLink="/blog-grid">Entries <abbr title="Really Simple Syndication">RSS</abbr></a></li>
                            <li><a routerLink="/blog-grid">Comments <abbr title="Really Simple Syndication">RSS</abbr></a></li>
                            <li><a routerLink="/blog-grid">WordPress.org</a></li>
                        </ul>
                    </section>

                    <section class="widget widget_tag_cloud">
                        <h3 class="widget-title">Tags</h3>

                        <div class="tagcloud">
                            <a routerLink="/blog-grid">Business <span class="tag-link-count"> (3)</span></a>
                            <a routerLink="/blog-grid">Design <span class="tag-link-count"> (3)</span></a>
                            <a routerLink="/blog-grid">IT <span class="tag-link-count"> (2)</span></a>
                            <a routerLink="/blog-grid">Marketing <span class="tag-link-count"> (2)</span></a>
                            <a routerLink="/blog-grid">Mobile <span class="tag-link-count"> (1)</span></a>
                            <a routerLink="/blog-grid">Protect <span class="tag-link-count"> (1)</span></a>
                            <a routerLink="/blog-grid">Startup <span class="tag-link-count"> (1)</span></a>
                            <a routerLink="/blog-grid">Tips <span class="tag-link-count"> (2)</span></a>
                        </div>
                    </section>
                </aside>
            </div>
        </div>
    </div>
</section>

<app-offer></app-offer>

<app-footer-style-one></app-footer-style-one>