<header class="header-area header-style-two">
    <div class="top-header">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-5 col-md-5">
                    <div class="top-header-left">
                        <p><span>Call Now At:</span> <a href="tel:+8005671234">+800-567-1234</a></p>
                    </div>
                </div>

                <div class="col-lg-7 col-md-7">
                    <div class="top-header-right">
                        <div class="login-signup-btn">
                            <p><a routerLink="/">Login</a> <span>or</span> <a routerLink="/">Register</a></p>
                        </div>
                        <ul class="social">
                            <li><a href="#" target="_blank"><i class="fab fa-facebook-f"></i></a></li>
                            <li><a href="#" target="_blank"><i class="fab fa-twitter"></i></a></li>
                            <li><a href="#" target="_blank"><i class="fab fa-linkedin-in"></i></a></li>
                            <li><a href="#" target="_blank"><i class="fab fa-instagram"></i></a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
    
    <div class="navbar-area">
        <div class="vimi-mobile-nav">
            <div class="logo">
                <a routerLink="/home-two"><img src="assets/img/logo.png" alt="logo"></a>
            </div>
        </div>

        <div class="vimi-nav">
            <div class="container">
                <nav class="navbar navbar-expand-md navbar-light">
                    <a class="navbar-brand" routerLink="/home-two"><img src="assets/img/logo.png" alt="logo"></a>

                    <div class="collapse navbar-collapse mean-menu" id="navbarSupportedContent">
                        <ul class="navbar-nav">
                            <li class="nav-item">
                                <a href="javascript:void(0)" class="nav-link">Home <i class="fas fa-chevron-down"></i></a>

                                <ul class="dropdown-menu">
                                    <li class="nav-item"><a routerLink="/" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Home One</a></li>

                                    <li class="nav-item"><a routerLink="/home-two" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Home Two</a></li>
                                </ul>
                            </li>

                            <li class="nav-item">
                                <a href="javascript:void(0)" class="nav-link">Pages <i class="fas fa-chevron-down"></i></a>

                                <ul class="dropdown-menu">
                                    <li class="nav-item"><a href="javascript:void(0)" class="nav-link">About Us</a>
                                        <ul class="dropdown-menu">
                                            <li class="nav-item"><a routerLink="/about-us-1" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">About Us 1</a></li>

                                            <li class="nav-item"><a routerLink="/about-us-2" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">About Us 2</a></li>
                                        </ul>
                                    </li>

                                    <li class="nav-item"><a routerLink="/team" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Team</a></li>

                                    <li class="nav-item"><a routerLink="/pricing" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Pricing</a></li>

                                    <li class="nav-item"><a routerLink="/error" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">404 Error</a></li>

                                    <li class="nav-item"><a routerLink="/faq" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">FAQ</a></li>
                                </ul>
                            </li>

                            <li class="nav-item">
                                <a href="javascript:void(0)" class="nav-link">Services <i class="fas fa-chevron-down"></i></a>

                                <ul class="dropdown-menu">
                                    <li class="nav-item"><a routerLink="/services-1" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Services Style 1</a></li>

                                    <li class="nav-item"><a routerLink="/services-2" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Services Style 2</a></li>

                                    <li class="nav-item"><a routerLink="/single-services" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Services Details</a></li>
                                </ul>
                            </li>

                            <li class="nav-item">
                                <a href="javascript:void(0)" class="nav-link">Shop <i class="fas fa-chevron-down"></i></a>

                                <ul class="dropdown-menu">
                                    <li class="nav-item"><a routerLink="/products" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Products List</a></li>

                                    <li class="nav-item"><a routerLink="/single-products" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Single Products</a></li>

                                    <li class="nav-item"><a routerLink="/cart" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Cart</a></li>

                                    <li class="nav-item"><a routerLink="/checkout" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Checkout</a></li>
                                </ul>
                            </li>

                            <li class="nav-item">
                                <a href="javascript:void(0)" class="nav-link">Blog <i class="fas fa-chevron-down"></i></a>

                                <ul class="dropdown-menu">
                                    <li class="nav-item"><a routerLink="/blog-grid" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Blog Grid</a></li>

                                    <li class="nav-item"><a routerLink="/blog-right-sidebar" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Blog Right Sidebar</a></li>

                                    <li class="nav-item"><a routerLink="/single-blog" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Blog Details</a></li>
                                </ul>
                            </li>

                            <li class="nav-item"><a routerLink="/contact" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Contact</a></li>
                        </ul>

                        <div class="others-options">
                            <a routerLink="/contact" class="btn btn-primary">Get Started</a>
                        </div>
                    </div>
                </nav>
            </div>
        </div>
    </div>
</header>