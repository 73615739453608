<app-navbar-style-one></app-navbar-style-one>

<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2>Blog</h2>
            <ul>
                <li><a href="index.html">Home</a></li>
                <li>Blog</li>
            </ul>
        </div>
    </div>
</div>

<section class="blog-area ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="single-blog-post">
                    <a routerLink="/single-blog" class="post-image"><img src="assets/img/blog-img1.jpg" alt="blog-image"></a>
                    <div class="blog-post-content">
                        <ul>
                            <li><a routerLink="/blog-grid">Admin</a></li>
                            <li><i class="far fa-clock"></i> January 23, 2020</li>
                        </ul>
                        <h3><a routerLink="/single-blog">I Used The Web For A Day On A 50 MB Budget</a></h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi turpis massa, dapibus nec libero vitae.</p>
                        <a routerLink="/single-blog" class="read-more-btn">Read More <i class="fas fa-angle-double-right"></i></a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-blog-post">
                    <a routerLink="/single-blog" class="post-image"><img src="assets/img/blog-img2.jpg" alt="blog-image"></a>
                    <div class="blog-post-content">
                        <ul>
                            <li><a routerLink="/blog-grid">Admin</a></li>
                            <li><i class="far fa-clock"></i> January 25, 2020</li>
                        </ul>
                        <h3><a routerLink="/single-blog">The hardest leadership advice to follow</a></h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi turpis massa, dapibus nec libero vitae.</p>
                        <a routerLink="/single-blog" class="read-more-btn">Read More <i class="fas fa-angle-double-right"></i></a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-blog-post">
                    <a routerLink="/single-blog" class="post-image"><img src="assets/img/blog-img3.jpg" alt="blog-image"></a>
                    <div class="blog-post-content">
                        <ul>
                            <li><a routerLink="/blog-grid">Admin</a></li>
                            <li><i class="far fa-clock"></i> January 27, 2020</li>
                        </ul>
                        <h3><a routerLink="/single-blog">The Most Popular New Top Business Apps</a></h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi turpis massa, dapibus nec libero vitae.</p>
                        <a routerLink="/single-blog" class="read-more-btn">Read More <i class="fas fa-angle-double-right"></i></a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-blog-post">
                    <a routerLink="/single-blog" class="post-image"><img src="assets/img/blog-img4.jpg" alt="blog-image"></a>
                    <div class="blog-post-content">
                        <ul>
                            <li><a routerLink="/blog-grid">Admin</a></li>
                            <li><i class="far fa-clock"></i> January 27, 2020</li>
                        </ul>
                        <h3><a routerLink="/single-blog">How to change yourself for the better</a></h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi turpis massa, dapibus nec libero vitae.</p>
                        <a routerLink="/single-blog" class="read-more-btn">Read More <i class="fas fa-angle-double-right"></i></a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-blog-post">
                    <a routerLink="/single-blog" class="post-image"><img src="assets/img/blog-img5.jpg" alt="blog-image"></a>
                    <div class="blog-post-content">
                        <ul>
                            <li><a routerLink="/blog-grid">Admin</a></li>
                            <li><i class="far fa-clock"></i> January 27, 2020</li>
                        </ul>
                        <h3><a routerLink="/single-blog">10 Tactics for marketing your company</a></h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi turpis massa, dapibus nec libero vitae.</p>
                        <a routerLink="/single-blog" class="read-more-btn">Read More <i class="fas fa-angle-double-right"></i></a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-blog-post">
                    <a routerLink="/single-blog" class="post-image"><img src="assets/img/blog-img6.jpg" alt="blog-image"></a>
                    <div class="blog-post-content">
                        <ul>
                            <li><a routerLink="/blog-grid">Admin</a></li>
                            <li><i class="far fa-clock"></i> January 04, 2020</li>
                        </ul>
                        <h3><a routerLink="/single-blog">Top 10 Google web ranking changing much</a></h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi turpis massa, dapibus nec libero vitae.</p>
                        <a routerLink="/single-blog" class="read-more-btn">Read More <i class="fas fa-angle-double-right"></i></a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-blog-post">
                    <a routerLink="/single-blog" class="post-image"><img src="assets/img/blog-img7.jpg" alt="blog-image"></a>
                    <div class="blog-post-content">
                        <ul>
                            <li><a routerLink="/blog-grid">Admin</a></li>
                            <li><i class="far fa-clock"></i> January 21, 2020</li>
                        </ul>
                        <h3><a routerLink="/single-blog">How to be a successful entrepreneur</a></h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi turpis massa, dapibus nec libero vitae.</p>
                        <a routerLink="/single-blog" class="read-more-btn">Read More <i class="fas fa-angle-double-right"></i></a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-blog-post">
                    <a routerLink="/single-blog" class="post-image"><img src="assets/img/blog-img8.jpg" alt="blog-image"></a>
                    <div class="blog-post-content">
                        <ul>
                            <li><a routerLink="/blog-grid">Admin</a></li>
                            <li><i class="far fa-clock"></i> January 15, 2020</li>
                        </ul>
                        <h3><a routerLink="/single-blog">Protect your workplace from cyber attacks</a></h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi turpis massa, dapibus nec libero vitae.</p>
                        <a routerLink="/single-blog" class="read-more-btn">Read More <i class="fas fa-angle-double-right"></i></a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-blog-post">
                    <a routerLink="/single-blog" class="post-image"><img src="assets/img/blog-img9.jpg" alt="blog-image"></a>
                    <div class="blog-post-content">
                        <ul>
                            <li><a routerLink="/blog-grid">Admin</a></li>
                            <li><i class="far fa-clock"></i> January 09, 2020</li>
                        </ul>
                        <h3><a routerLink="/single-blog">How to start your business as an entrepreneur</a></h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi turpis massa, dapibus nec libero vitae.</p>
                        <a routerLink="/single-blog" class="read-more-btn">Read More <i class="fas fa-angle-double-right"></i></a>
                    </div>
                </div>
            </div>

            <div class="col-lg-12 col-md-12">
                <div class="pagination-area">
                    <a routerLink="/blog-grid" class="prev page-numbers"><i class="fas fa-angle-double-left"></i></a>
                    <a routerLink="/blog-grid" class="page-numbers">1</a>
                    <span class="page-numbers current" aria-current="page">2</span>
                    <a routerLink="/blog-grid" class="page-numbers">3</a>
                    <a routerLink="/blog-grid" class="page-numbers">4</a>
                    <a routerLink="/blog-grid" class="next page-numbers"><i class="fas fa-angle-double-right"></i></a>
                </div>
            </div>
        </div>
    </div>
</section>

<app-footer-style-one></app-footer-style-one>