<section class="offer-area ptb-100 jarallax" data-jarallax='{"speed": 0.2}'>
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="offer-content">
                    <span class="sub-title">Cost a little get a best</span>
                    <h2>200 Mbps + VimiTV</h2>
                    <ul class="list">
                        <li><i class="flaticon-online-shop"></i> 160+ Channels</li>
                        <li><i class="flaticon-router"></i> Internet</li>
                    </ul>
                    <div class="price">
                        <span class="old-price">$42.99/mo</span>
                        <span class="new-price">$35.99/mo</span>
                    </div>
                    <ul class="features-list">
                        <li>Line rental and Vimi Talk included</li>
                        <li>+ one-off $9.95 set-up fee</li>
                    </ul>
                    <a routerLink="/contact" class="btn btn-primary">Request A Quote</a>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="offer-time">
                    <h2>Hurry! This offer ends in:</h2>
                    <div id="timer">
                        <div id="days">{{days}} <span>Days</span></div>
                        <div id="hours">{{hours}} <span>Hours</span></div>
                        <div id="minutes">{{minutes}} <span>Minutes</span></div>
                        <div id="seconds">{{seconds}} <span>Seconds</span></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>