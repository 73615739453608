<app-navbar-style-one></app-navbar-style-one>

<section class="error-area ptb-100">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="error-content">
                    <img src="assets/img/404.png" alt="error">
                    <h3>Page Not Found</h3>
                    <p>The page you are looking for might have been removed had its name changed or is temporarily unavailable.</p>
                    <a routerLink="/" class="btn btn-primary">Go to Home</a>
                </div>
            </div>
        </div>
    </div>
</section>

<app-footer-style-one></app-footer-style-one>