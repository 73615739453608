<app-navbar-style-one></app-navbar-style-one>

<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2>Contact</h2>
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li>Contact</li>
            </ul>
        </div>
    </div>
</div>


<section class="contact-info-box-area ptb-100 pb-0">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-contact-info">
                    <div class="icon">
                        <i class="flaticon-place"></i>
                    </div>
                    <h3>Registered & Operational Address</h3>
                    <p>Office No. 404, 1 New Palasia Silver Arched Indore Madhya Pradesh 452001</p>
                    <div class="image-box">
                        <img src="assets/img/shape-image/1.png" alt="image">
                        <img src="assets/img/shape-image/1.png" alt="image">
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-contact-info">
                    <div class="icon">
                        <i class="flaticon-address-book"></i>
                    </div>
                    <h3>Email</h3>
                    <p><a href="mailto:info@airnet360.com">tehsin@airnet360.com</a></p>
                    <div class="image-box">
                        <img src="assets/img/shape-image/1.png" alt="image">
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6 offset-lg-0 offset-md-3 offset-sm-3">
                <div class="single-contact-info">
                    <div class="icon">
                        <i class="flaticon-signal"></i>
                    </div>
                    <h3>Phone</h3>
                    <p><a href="tel:07314894499">0731-4894499</a></p>
                    <p><a href="tel:07314894488">0731-4894488</a></p>
                    <div class="image-box">
                        <img src="assets/img/shape-image/1.png" alt="image">
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="contact-area ptb-100">
    <div class="container">
        <div class="section-title">
            <span>Send Message</span>
            <h2>Drop us message for any query</h2>
        </div>
        <div *ngIf="!isLoading; else showLoader">

            <form id="contactForm" [formGroup]="contactForm" (submit)="onSubmit()">
                <div class="alert alert-success text-start" role="alert" *ngIf="showMessage">
                    Thanks for submitting your interest. We will contact you soon.
                </div>
                <div class="row">
                    <div class="col-lg-6 col-md-6">
                        <div class="form-group">
                            <input type="text" class="form-control" placeholder="Your Name*" formControlName="name" id="name">
                            <div *ngIf="f['name'].touched && f['name'].invalid" class="text-start">
                                <label *ngIf="f['name'].errors && f['name'].errors['required']" class="text-danger">This field is required</label>
                            </div>

                        </div>
                    </div>

                    <div class="col-lg-6 col-md-6">
                        <div class="form-group">
                            <input type="email" class="form-control" placeholder="Your Email Address*" formControlName="email" id="email">
                            <div *ngIf="f['email'].touched && f['email'].invalid" class="text-start">
                                <label *ngIf="f['email'].errors && f['email'].errors['required']" class="text-danger" translate>Should be a valid email</label>
                                <span class="text-danger" *ngIf="f['email'].hasError('pattern') && (f['email'].dirty || f['email'].touched)" translate>Please enter a valid email address</span>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-6">
                        <div class="form-group">
                            <input type="text" class="form-control" formControlName="phone_number" placeholder="Your Phone Number*" id="phone_number">
                            <div *ngIf="f['phone_number'].touched && f['phone_number'].invalid" class="text-start">
                                <label *ngIf="f['phone_number'].errors && f['phone_number'].errors['required']" class="text-danger">This field is required</label>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-6">
                        <div class="form-group">
                            <input type="text" class="form-control" formControlName="msg_subject" placeholder="Your Subject*" id="msg_subject">
                            <div *ngIf="f['msg_subject'].touched && f['msg_subject'].invalid" class="text-start">
                                <label *ngIf="f['msg_subject'].errors && f['msg_subject'].errors['required']" class="text-danger">This field is required</label>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-12 col-md-12">
                        <div class="form-group">
                            <textarea formControlName="message" class="form-control" id="message" cols="30" rows="10" placeholder="Type Your Message Here*"></textarea>
                            <div *ngIf="f['message'].touched && f['message'].invalid" class="text-start">
                                <label *ngIf="f['message'].errors && f['message'].errors['required']" class="text-danger">This field is required</label>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-12 col-md-12">
                        <button type="submit" class="btn btn-primary" [disabled]="contactForm.invalid">Send Message</button>
                    </div>
                </div>
            </form>
        </div>


        <ng-template #showLoader>
            <div class="overlay">
                <div class="overlay__inner">
                    <div class="overlay__content"><span class="spinner"></span></div>
                </div>
            </div>
        </ng-template>

    </div>
</section>


<app-footer-style-one></app-footer-style-one>