<app-navbar-style-one></app-navbar-style-one>

<div class="main-banner">
    <div class="container">
        <div class="row align-items-center m-0">
            <div class="col-lg-6 col-md-12 p-0">
                <div class="main-banner-content">
                    <span class="sub-title"><i class="flaticon-wifi-signal"></i> Airnet360 Broadband Service</span>
                    <h1>Enjoy the next generation internet</h1>
                    <p>The Next-Generation Internet strives to be a faster communication network, with a transfer speed of 100–1000 times that of conventional Internet access - continuing to build the connections that matter.</p>
                    <div class="price">
                        Rs.500 <span>/mo</span>
                    </div>
                    <div class="btn-box">
                        <a routerLink="/contact" class="btn btn-primary">Request A Quote</a>

                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-md-12 p-0">
                <div class="banner-image">
                    <img src="assets/img/banner-img1.jpg" alt="image">
                </div>
            </div>
        </div>
    </div>

    <div class="shape-img1"><img src="assets/img/shape-image/1.png" alt="imgae"></div>
</div>

<section class="features-area bg-image ptb-100">
    <div class="container">
        <div class="section-title">
            <span>
                <span class="icon">
                    <i class="flaticon-wifi"></i>
                </span>
            <span>Airnet360 Features</span>
            </span>
            <h2>We are internet service provider company in Indore, Madhya Pradesh.</h2>
        </div>

        <div class="row">
            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="single-features-box">
                    <div class="icon">
                        <i class="flaticon-speedometer"></i>
                    </div>
                    <h3>CONNECTION JUST FOR YOU</h3>
                    <p>Airnet360 is all about peoples' satisfaction. The connection speaks everything you want</p>
                    <div class="back-icon">
                        <i class="flaticon-speedometer"></i>
                    </div>
                    <a routerLink="/about-us-1" class="details-btn"><i class="flaticon-arrow-pointing-to-right"></i></a>
                    <div class="image-box">
                        <img src="assets/img/shape-image/2.png" alt="image">
                        <img src="assets/img/shape-image/2.png" alt="image">
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="single-features-box">
                    <div class="icon">
                        <i class="flaticon-speedometer-1"></i>
                    </div>
                    <h3>STEADY NETWORK</h3>
                    <p>Keep your game going with our steady Network - powered by the reliability of fiber</p>
                    <div class="back-icon">
                        <i class="flaticon-speedometer-1"></i>
                    </div>
                    <a routerLink="/about-us-1" class="details-btn"><i class="flaticon-arrow-pointing-to-right"></i></a>
                    <div class="image-box">
                        <img src="assets/img/shape-image/2.png" alt="image">
                        <img src="assets/img/shape-image/2.png" alt="image">
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-md-6 col-sm-6 offset-lg-0 offset-md-3 offset-sm-3">
                <div class="single-features-box">
                    <div class="icon">
                        <i class="flaticon-support"></i>
                    </div>
                    <h3>NO DATA LIMIT</h3>
                    <p>Forget the limit and keep your winning streak by subscribing to this plan</p>
                    <div class="back-icon">
                        <i class="flaticon-support"></i>
                    </div>
                    <a routerLink="/about-us-1" class="details-btn"><i class="flaticon-arrow-pointing-to-right"></i></a>
                    <div class="image-box">
                        <img src="assets/img/shape-image/2.png" alt="image">
                        <img src="assets/img/shape-image/2.png" alt="image">
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-md-6 col-sm-6 offset-lg-0 offset-md-3 offset-sm-3">
                <div class="single-features-box">
                    <div class="icon">
                        <i class="flaticon-support"></i>
                    </div>
                    <h3>2 X SPEED</h3>
                    <p>Choose this yearly plan, saying out loud, "Better Connection for Better Engagement"</p>
                    <div class="back-icon">
                        <i class="flaticon-support"></i>
                    </div>
                    <a routerLink="/about-us-1" class="details-btn"><i class="flaticon-arrow-pointing-to-right"></i></a>
                    <div class="image-box">
                        <img src="assets/img/shape-image/2.png" alt="image">
                        <img src="assets/img/shape-image/2.png" alt="image">
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-md-6 col-sm-6 offset-lg-0 offset-md-3 offset-sm-3">
                <div class="single-features-box">
                    <div class="icon">
                        <i class="flaticon-support"></i>
                    </div>
                    <h3>COMBINE INTERNET, TV, AND PHONE</h3>
                    <p>Bring your family and friends together with this lightning speed plan</p>
                    <div class="back-icon">
                        <i class="flaticon-support"></i>
                    </div>
                    <a routerLink="/about-us-1" class="details-btn"><i class="flaticon-arrow-pointing-to-right"></i></a>
                    <div class="image-box">
                        <img src="assets/img/shape-image/2.png" alt="image">
                        <img src="assets/img/shape-image/2.png" alt="image">
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-md-6 col-sm-6 offset-lg-0 offset-md-3 offset-sm-3">
                <div class="single-features-box">
                    <div class="icon">
                        <i class="flaticon-support"></i>
                    </div>
                    <h3>GET THE RIGHT PLAN FOR YOU</h3>
                    <p>Right, plan for the right you - bringing the fastest speed with cutting-edge technology</p>
                    <div class="back-icon">
                        <i class="flaticon-support"></i>
                    </div>
                    <a routerLink="/about-us-1" class="details-btn"><i class="flaticon-arrow-pointing-to-right"></i></a>
                    <div class="image-box">
                        <img src="assets/img/shape-image/2.png" alt="image">
                        <img src="assets/img/shape-image/2.png" alt="image">
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-md-6 col-sm-6 offset-lg-0 offset-md-3 offset-sm-3">
                <div class="single-features-box">
                    <div class="icon">
                        <i class="flaticon-support"></i>
                    </div>
                    <h3>BEST CONNECTIVITY</h3>
                    <p>We have to access network-ready access that matters to you most</p>
                    <div class="back-icon">
                        <i class="flaticon-support"></i>
                    </div>
                    <a routerLink="/about-us-1" class="details-btn"><i class="flaticon-arrow-pointing-to-right"></i></a>
                    <div class="image-box">
                        <img src="assets/img/shape-image/2.png" alt="image">
                        <img src="assets/img/shape-image/2.png" alt="image">
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-md-6 col-sm-6 offset-lg-0 offset-md-3 offset-sm-3">
                <div class="single-features-box">
                    <div class="icon">
                        <i class="flaticon-support"></i>
                    </div>
                    <h3>PLAN YOU NEED FOR ENTERTAINMENT</h3>
                    <p>Double your entertainment with this plan and stop missing what you've been missing watching</p>
                    <div class="back-icon">
                        <i class="flaticon-support"></i>
                    </div>
                    <a routerLink="/about-us-1" class="details-btn"><i class="flaticon-arrow-pointing-to-right"></i></a>
                    <div class="image-box">
                        <img src="assets/img/shape-image/2.png" alt="image">
                        <img src="assets/img/shape-image/2.png" alt="image">
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="about-area ptb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="about-content">
                    <span class="sub-title"><i class="flaticon-care-about-environment"></i> About Airnet360</span>
                    <h2>MAKING A CHANGE IN INTERNET INDUSTRY</h2>
                    <p>Airnet360, bringing the internet to you beyond expectations. We are India's leading value for money connectivity provider with more than 5 thousand consumers in 4 different cities. Our vision is to offer a simple, affordable, reliable,
                        and fair connection to everyone. We were founded with the aim and mission of offering more choice and better value for customers - making the internet truly accessible to everyone. We have always challenged the status quo by launching
                        first-of-a-kind services and making changes to the internet industry.</p>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="about-image">
                    <img src="assets/img/internet.jpeg" alt="image">
                </div>
            </div>
        </div>
    </div>
</section>

<section class="services-area bg-image ptb-100">
    <div class="container">
        <div class="section-title">
            <span>
                <span class="icon">
                    <i class="flaticon-technical-support"></i>
                </span>
            <span>Airnet360 Services</span>
            </span>
            <h2>Explore the next Isp unlimited possibilities</h2>
        </div>

        <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-services-box">
                    <div class="icon">
                        <i class="flaticon-router"></i>
                    </div>
                    <h3>Business Broadband</h3>
                    <p>Because big dreams deserve big connections</p>
                    <!-- <a routerLink="/single-services" class="read-more-btn">Read More <i class="flaticon-arrow-pointing-to-right"></i></a> -->
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-services-box">
                    <div class="icon">
                        <i class="flaticon-wifi-1"></i>
                    </div>
                    <h3>Personal and Residential Broadband</h3>
                    <p>Support that keeps you connected because faster brings us closer</p>
                    <!-- <a routerLink="/single-services" class="read-more-btn">Read More <i class="flaticon-arrow-pointing-to-right"></i></a> -->
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-services-box">
                    <div class="icon">
                        <i class="flaticon-wifi-signal-tower"></i>
                    </div>
                    <h3>Enterprises Broadband</h3>
                    <p>Superfast is good but lightning-fast is better. Because the more speed you have, the more you can do</p>
                    <!-- <a routerLink="/single-services" class="read-more-btn">Read More <i class="flaticon-arrow-pointing-to-right"></i></a> -->
                </div>
            </div>

        </div>
    </div>
</section>
<!--
<section class="pricing-area ptb-100 extra-mb pb-0">
    <div class="container">
        <div class="section-title">
            <span>
                <span class="icon">
                    <i class="flaticon-resume"></i>
                </span>
                <span>Airnet360 Pricing</span>
            </span>
            <h2>Discover our best plans</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices.</p>
        </div>

        <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-pricing-table">
                    <div class="pricing-header">
                        <div class="icon">
                            <i class="flaticon-online-shop"></i>
                        </div>
                        <span>TV + Internet</span>
                        <h3>Player Bundle</h3>
                    </div>
                    <ul class="pricing-features-list">
                        <li><i class="flaticon-check-mark"></i> 150+ channels</li>
                        <li><i class="flaticon-check-mark"></i> Catch Up & On Demand</li>
                        <li><i class="flaticon-check-mark"></i> Cell Phone Connection</li>
                        <li><i class="flaticon-check-mark"></i> Up to 100Mbps fibre</li>
                        <li><i class="flaticon-check-mark"></i> Made for 1-4 devices</li>
                    </ul>
                    <div class="price">
                        <span>From</span>
                        <span>$</span>
                        67.99
                        <span>/mo</span>
                    </div>
                    <a routerLink="/" class="view-plans-btn">View Plans</a>
                    <div class="image-box">
                        <img src="assets/img/shape-image/2.png" alt="image">
                        <img src="assets/img/shape-image/2.png" alt="image">
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-pricing-table active">
                    <div class="pricing-header">
                        <div class="icon">
                            <i class="flaticon-online-shop"></i>
                        </div>
                        <span>TV + Internet</span>
                        <h3>Vimi TV Box</h3>
                    </div>
                    <ul class="pricing-features-list">
                        <li><i class="flaticon-check-mark"></i> 150+ channels</li>
                        <li><i class="flaticon-check-mark"></i> Catch Up & On Demand</li>
                        <li><i class="flaticon-check-mark"></i> Cell Phone Connection</li>
                        <li><i class="flaticon-check-mark"></i> Up to 100Mbps fibre</li>
                        <li><i class="flaticon-check-mark"></i> Made for 1-4 devices</li>
                    </ul>
                    <div class="price">
                        <span>From</span>
                        <span>$</span>
                        79.99
                        <span>/mo</span>
                    </div>
                    <a routerLink="/" class="view-plans-btn">View Plans</a>
                    <div class="image-box">
                        <img src="assets/img/shape-image/2.png" alt="image">
                        <img src="assets/img/shape-image/2.png" alt="image">
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6 offset-lg-0 offset-md-3 offset-sm-3">
                <div class="single-pricing-table">
                    <div class="pricing-header">
                        <div class="icon">
                            <i class="flaticon-online-shop"></i>
                        </div>
                        <span>TV + Internet</span>
                        <h3>Broadband & WIFI</h3>
                    </div>
                    <ul class="pricing-features-list">
                        <li><i class="flaticon-check-mark"></i> 150+ channels</li>
                        <li><i class="flaticon-check-mark"></i> Catch Up & On Demand</li>
                        <li><i class="flaticon-check-mark"></i> Cell Phone Connection</li>
                        <li><i class="flaticon-check-mark"></i> Up to 100Mbps fibre</li>
                        <li><i class="flaticon-check-mark"></i> Made for 1-4 devices</li>
                    </ul>
                    <div class="price">
                        <span>From</span>
                        <span>$</span>
                        99.99
                        <span>/mo</span>
                    </div>
                    <a routerLink="/" class="view-plans-btn">View Plans</a>
                    <div class="image-box">
                        <img src="assets/img/shape-image/2.png" alt="image">
                        <img src="assets/img/shape-image/2.png" alt="image">
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
-->
<section class="faq-area bg-image ptb-100">
    <div class="container">
        <div class="section-title">
            <span>
                <span class="icon">
                    <i class="flaticon-help"></i>
                </span>
            <span>Frequently Questions</span>
            </span>
            <h2>Why you should choose <br>our services</h2>
        </div>

        <div class="row">
            <div class="col-lg-12 col-md-12">
                <div class="faq-accordion">
                    <ul class="accordion">
                        <li class="accordion-item">
                            <a class="accordion-title" href="javascript:void(0)"><i class="flaticon-add"></i> Difference between dealing with Airnet360 and directly with a carrier?</a>
                            <p class="accordion-content">As opposed to a telecom carrier, we are a dedicated internet service provider company and this is what we do. We have a presence in every city we operate in and being the specialized internet service provider, means for you,
                                faster connection setups, better speeds, dedicated bandwidths, and speedy issue resolution.</p>
                        </li>
                        <li class="accordion-item">
                            <a class="accordion-title" href="javascript:void(0)"><i class="flaticon-add"></i> Can Airnet360 consolidate services?</a>
                            <p class="accordion-content">Yes. You can subscribe to Phone and IPTV along with our internet connection.</p>
                        </li>
                        <li class="accordion-item">
                            <a class="accordion-title" href="javascript:void(0)"><i class="flaticon-add"></i> Does the speed remain the same in all areas?</a>
                            <p class="accordion-content">Yes! Airnet360 has the industry-leading infrastructure and fiber optic network which ensures that you get the best ping and internet at any time.</p>
                        </li>
                        <li class="accordion-item">
                            <a class="accordion-title" href="javascript:void(0)"><i class="flaticon-add"></i> Is this undoubtedly the best service?</a>
                            <p class="accordion-content">At Airnet360, the focus of everything we do is you. From installing the best network there is to hassle-free connection setup, speedy issue resolution, and 99.99% uptime, we undoubtedly are the best internet service, providers.</p>
                        </li>
                        <li class="accordion-item">
                            <a class="accordion-title" href="javascript:void(0)"><i class="flaticon-add"></i> How is the pricing compared to others?</a>
                            <p class="accordion-content">We aim to serve our customers with the best in class internet services at the most reasonable prices and we assure you, with the service quality like ours, no one else in the market can beat us at price.</p>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</section>


<app-footer-style-one></app-footer-style-one>