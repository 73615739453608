<app-navbar-style-one></app-navbar-style-one>

<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2>Pricing</h2>
            <ul>
                <li><a href="index.html">Home</a></li>
                <li>Pricing</li>
            </ul>
        </div>
    </div>
</div>

<section class="pricing-area ptb-100">
    <div class="container">
        <div class="section-title">
            <span>
                <span class="icon">
                    <i class="flaticon-resume"></i>
                </span>
                <span>Vimi Pricing</span>
            </span>
            <h2>Discover our best plans</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices.</p>
        </div>

        <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-pricing-table">
                    <div class="pricing-header">
                        <div class="icon">
                            <i class="flaticon-online-shop"></i>
                        </div>
                        <span>TV + Internet</span>
                        <h3>Player Bundle</h3>
                    </div>
                    <ul class="pricing-features-list">
                        <li><i class="flaticon-check-mark"></i> 150+ channels</li>
                        <li><i class="flaticon-check-mark"></i> Catch Up & On Demand</li>
                        <li><i class="flaticon-check-mark"></i> Cell Phone Connection</li>
                        <li><i class="flaticon-check-mark"></i> Up to 100Mbps fibre</li>
                        <li><i class="flaticon-check-mark"></i> Made for 1-4 devices</li>
                    </ul>
                    <div class="price">
                        <span>From</span>
                        <span>$</span>
                        67.99
                        <span>/mo</span>
                    </div>
                    <a routerLink="/" class="view-plans-btn">View Plans</a>
                    <div class="image-box">
                        <img src="assets/img/shape-image/2.png" alt="image">
                        <img src="assets/img/shape-image/2.png" alt="image">
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-pricing-table active">
                    <div class="pricing-header">
                        <div class="icon">
                            <i class="flaticon-online-shop"></i>
                        </div>
                        <span>TV + Internet</span>
                        <h3>Vimi TV Box</h3>
                    </div>
                    <ul class="pricing-features-list">
                        <li><i class="flaticon-check-mark"></i> 150+ channels</li>
                        <li><i class="flaticon-check-mark"></i> Catch Up & On Demand</li>
                        <li><i class="flaticon-check-mark"></i> Cell Phone Connection</li>
                        <li><i class="flaticon-check-mark"></i> Up to 100Mbps fibre</li>
                        <li><i class="flaticon-check-mark"></i> Made for 1-4 devices</li>
                    </ul>
                    <div class="price">
                        <span>From</span>
                        <span>$</span>
                        79.99
                        <span>/mo</span>
                    </div>
                    <a routerLink="/" class="view-plans-btn">View Plans</a>
                    <div class="image-box">
                        <img src="assets/img/shape-image/2.png" alt="image">
                        <img src="assets/img/shape-image/2.png" alt="image">
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6 offset-lg-0 offset-md-3 offset-sm-3">
                <div class="single-pricing-table">
                    <div class="pricing-header">
                        <div class="icon">
                            <i class="flaticon-online-shop"></i>
                        </div>
                        <span>TV + Internet</span>
                        <h3>Broadband & WIFI</h3>
                    </div>
                    <ul class="pricing-features-list">
                        <li><i class="flaticon-check-mark"></i> 150+ channels</li>
                        <li><i class="flaticon-check-mark"></i> Catch Up & On Demand</li>
                        <li><i class="flaticon-check-mark"></i> Cell Phone Connection</li>
                        <li><i class="flaticon-check-mark"></i> Up to 100Mbps fibre</li>
                        <li><i class="flaticon-check-mark"></i> Made for 1-4 devices</li>
                    </ul>
                    <div class="price">
                        <span>From</span>
                        <span>$</span>
                        99.99
                        <span>/mo</span>
                    </div>
                    <a routerLink="/" class="view-plans-btn">View Plans</a>
                    <div class="image-box">
                        <img src="assets/img/shape-image/2.png" alt="image">
                        <img src="assets/img/shape-image/2.png" alt="image">
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="pricing-about-area ptb-100 pt-0">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="pricing-about-content">
                    <span>Vimi About</span>
                    <h2>A Few Things to NoteAbout Our Broadband Plans</h2>
                    <p>Line speed refers to the <b>max port access speed.</b> Actual speeds may differ due to various factors including your equipment, the capacity of our systems and our suppliers, the number of end-users using the service, the applications you are using and the websites you are accessing. Once you have reached the data allowance for your plan, you will be <b>charged excess data charges of $2 per GB.</b></p>
                    <a routerLink="/contact" class="btn btn-primary">Request A Quote</a>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="about-main-image">
                    <img src="assets/img/about-img2.jpg" alt="image">
                </div>
            </div>
        </div>
    </div>
</section>

<app-footer-style-one></app-footer-style-one>