<app-navbar-style-one></app-navbar-style-one>

<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2>About Us</h2>
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li>About Us</li>
            </ul>
        </div>
    </div>
</div>

<section class="about-area ptb-100">
    <div class="container">
        <div class="section-title">
            <span>
                <span class="icon">
                    <i class="flaticon-technical-support"></i>
                </span>
            <span>Airnet360 About</span>
            </span>
            <h2>Our purpose-built full fiber network satisfies the needs of today, and will meet the demand of generation for years to come</h2>
        </div>

        <!-- <div class="about-inner-area">
            <div class="row">
                <div class="col-lg-4 col-md-6 col-sm-6">
                    <div class="single-about-box">
                        <div class="icon">
                            <i class="flaticon-support"></i>
                        </div>
                        <h3>Fully Staffed 24/7</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6 col-sm-6">
                    <div class="single-about-box">
                        <div class="icon">
                            <i class="flaticon-router"></i>
                        </div>
                        <h3>Full Network Visibility</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6 col-sm-6 offset-lg-0 offset-md-3 offset-sm-3">
                    <div class="single-about-box">
                        <div class="icon">
                            <i class="flaticon-wifi-signal-tower"></i>
                        </div>
                        <h3>Resilient Connectivity</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                    </div>
                </div>
            </div>
        </div> -->

        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="about-content">
                    <span class="sub-title"><i class="flaticon-care-about-environment"></i> About Airnet360</span>
                    <h2>Experience the magic of tech to serve you a best</h2>
                    <p>Airnet360, bringing the internet to you beyond expectations. We are India's leading value for money connectivity provider with more than 5 thousand consumers in 4 different cities. Our vision is to offer a simple, affordable, reliable,
                        and fair connection to everyone. We were founded with the aim and mission of offering more choice and better value for customers - making the internet truly accessible to everyone. We have always challenged the status quo by launching
                        first-of-a-kind services and making changes to the internet industry.</p>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="about-image">
                    <img src="assets/img/about.png" alt="image">
                </div>
            </div>
        </div>
    </div>
</section>
<!--
<section class="our-coverage-area ptb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="coverage-area-content">
                    <span class="sub-title"><i class="flaticon-care-about-environment"></i> About Airnet360</span>
                    <h2>Airnet360 Cover Your area</h2>
                    <p>We are available in Indore, Mhow, Khandwa, Bhurhanpur, Pithampur and many more.</p>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="coverage-area-map">
                    <img src="assets/img/map.png" alt="image">
                </div>
            </div>
        </div>
    </div>
</section>

-->
<!--
<section class="feedback-area ptb-100">
    <div class="container">
        <div class="section-title">
            <span>
                <span class="icon">
                    <i class="flaticon-help"></i>
                </span>
                <span>Feedback</span>
            </span>
            <h2>What customer say about us</h2>
        </div>
        <div class="feedback-slides owl-carousel owl-theme">
            <div class="single-feedback-item">
                <i class="flaticon-quote-left"></i>
                <p>Lorem Ipsum is simply dummy text of printing Lorem Ipsum has been the du industry's standard dummy text ever since the 1500s.</p>
                <div class="client-info">
                    <h3>Paul Pogba</h3>
                    <span>CEO at ThemeForest</span>
                    <img src="assets/img/author1.jpg" alt="image">
                </div>
            </div>
            <div class="single-feedback-item">
                <i class="flaticon-quote-left"></i>
                <p>Lorem Ipsum is simply dummy text of printing Lorem Ipsum has been the du industry's standard dummy text ever since the 1500s.</p>
                <div class="client-info">
                    <h3>Jason Roy</h3>
                    <span>CEO at ThemeForest</span>
                    <img src="assets/img/author2.jpg" alt="image">
                </div>
            </div>
            <div class="single-feedback-item">
                <i class="flaticon-quote-left"></i>
                <p>Lorem Ipsum is simply dummy text of printing Lorem Ipsum has been the du industry's standard dummy text ever since the 1500s.</p>
                <div class="client-info">
                    <h3>Sarah Taylor</h3>
                    <span>CEO at ThemeForest</span>
                    <img src="assets/img/author3.jpg" alt="image">
                </div>
            </div>
            <div class="single-feedback-item">
                <i class="flaticon-quote-left"></i>
                <p>Lorem Ipsum is simply dummy text of printing Lorem Ipsum has been the du industry's standard dummy text ever since the 1500s.</p>
                <div class="client-info">
                    <h3>James Anderson</h3>
                    <span>CEO at ThemeForest</span>
                    <img src="assets/img/author4.jpg" alt="image">
                </div>
            </div>
        </div>
    </div>
</section>
-->
<app-footer-style-one></app-footer-style-one>